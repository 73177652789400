<template>
  <div>
    <b-button v-b-modal.createTournamentModal v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-success"
      block>
      สร้าง
    </b-button>

    <b-modal v-model="modal" size="lg" id="createTournamentModal" title="สร้างภารกิจ">
      <div class="row">
        <div class="col-12">
          <b-form-group label="แบนเนอร์" label-for="banner">
            <b-form-file v-model="form.banner" placeholder="แบนเนอร์..." accept="image/*"></b-form-file>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="ประเภททัวร์นาเมนต์" label-for="type">
            <b-form-select v-model="form.type" :options="typeOptions"></b-form-select>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="ชื่อทัวร์นาเมนต์" label-for="name">
            <b-form-input v-model="form.name"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="กติกา" label-for="name">
            <b-form-textarea v-model="form.rule"></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col-2">
          <b-form-group label="ตั๋วที่ใช้" label-for="chip">
            <b-form-input type="number" v-model="form.chip"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-2">
          <b-form-group label="จำนวนสิทธิ" label-for="limit">
            <b-form-input type="number" v-model="form.limit"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-8">
          <b-form-group label="วันที่จบ:" label-for="close_at">
            <flat-pickr id="close_at" v-model="form.close_at" :config="closeAtConfig" class="form-control" />
          </b-form-group>
        </div>

        <div class="col-6" v-if="form.type === 'GAME'">
          <b-form-group label="ค่ายเกมที่แข่ง" label-for="product_id">
            <b-form-select v-model="form.product_id" :options="gameOptions" text-field="product_name"
              value-field="id"></b-form-select>
          </b-form-group>
        </div>
        <div class="col-6" v-if="form.type === 'GAME'">
          <b-form-group label="ประเภทการแข่ง" label-for="challenge">
            <b-form-select v-model="form.challenge" :options="challengeAOptions"></b-form-select>
          </b-form-group>
        </div>
        <div class="col-12" v-else>
          <b-form-group label="ประเภทการแข่ง" label-for="challenge">
            <b-form-select v-model="form.challenge" :options="challengeBOptions"></b-form-select>
          </b-form-group>
        </div>
      </div>

      <h5 class="text-primary">ของรางวัล</h5>
      <div class="row" v-for="(reward, index) in form.rewards">
        <div class="col-6 col-md-2">
          <b-form-group :label="`อันดับที่`" label-for="no">
            <b-form-input v-model="reward.no" :value="index + 1" placeholder="1, 4-10, 5-20"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6 col-md-2">
          <b-form-group :label="`ประเภท`" label-for="reward_type">
            <b-form-select v-model="reward.reward_type" :options="rewardOptions"></b-form-select>
          </b-form-group>
        </div>
        <div class="col-6 col-md-3">
          <b-form-group label="ชื่อ" label-for="name">
            <b-form-input v-model="reward.name"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6 col-md-2">
          <b-form-group label="มูลค่า" label-for="value">
            <b-form-input type="number" v-model="reward.value"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6 col-md-3">
          <b-form-group label="รูปภาพ" label-for="image">
            <b-form-file v-model="reward.image" placeholder="รูปภาพ..." accept="image/*"></b-form-file>
          </b-form-group>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <b-button @click="addReward" size="sm" variant="info">เพิ่มของรางวัล</b-button>
      </div>

      <template #modal-footer>
        <b-button variant="success" @click="submit()">
          ยืนยัน
        </b-button>
        <b-button variant="danger" @click="cancel()">
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BTab, BFormFile, BFormRadio, BTabs, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    BTab, BFormFile, BFormRadio, BTabs, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    flatPickr
  },
  directives: {
    Ripple
  },
  props: ['gameOptions'],
  emits: ['submit'],
  data: () => ({
    modal: false,
    typeOptions: [
      { text: 'เล่นเกม', value: 'GAME' },
      { text: 'ชวนเพื่อน', value: 'FRIEND' },
    ],
    challengeAOptions: [
      { text: 'Turnover', value: 'TURN' },
      { text: 'W/L', value: 'WL' },
      { text: '% กำไร', value: 'BENEFIT' },
    ],
    challengeBOptions: [
      { text: 'ยอดรวม จำนวนเพื่อน', value: 'FSUM' },
      { text: 'ยอดรวม Turnover ของเพื่อน', value: 'FTURN' },
      { text: 'ยอดรวม W/L ของเพื่อน', value: 'FWL' },
      { text: 'ยอดรวม % กำไร ของเพื่อน', value: 'FBENEFIT' },
    ],
    rewardOptions: [
      { text: 'สิ่งของ', value: 'A' },
      { text: 'เครดิต', value: 'B' },
    ],
    form: {
      type: 'GAME',
      name: '',
      rule: '',
      chip: '',
      limit: '',
      product_id: '',
      challenge: '',
      banner: null,
      rewards: [
        { no: 1, reward_type: 'A', name: '', value: '', image: null }
      ]
    },
    closeAtConfig: {
      enableTime: true,
      noCalendar: false,
      dateFormat: "d/m/Y H:i",
      time_24hr: true,
    },
  }),
  methods: {
    submit() {
      this.$emit('submit', this.form)
      this.modal = false
      this.clearForm();
    },
    addReward() {
      this.form.rewards.push(
        { no: this.form.rewards.length + 1, reward_type: 'A', name: '', value: '', image: null })
    },
    cancel() {
      this.modal = false;
      this.clearForm();
    },
    clearForm() {
      this.form = {
        type: 'GAME',
        name: '',
        rule: '',
        chip: '',
        limit: '',
        product_id: '',
        banner: null,
        challenge: '',
        rewards: [
          { no: 1, reward_type: 'A', name: '', value: '', image: null }
        ]
      }
    }
  }
}
</script>